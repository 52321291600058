<script>
import { authComputed } from "@/state/helpers";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Profile from "@/components/account/account-profile";
import Preloader from "@/components/preloader";
import ChangePassword from "@/components/account/account-change-password";
import appConfig from "@/app.config";
import Repository from "@/app/repository/repository-factory";

const UserAccountRepository = Repository.get("UserAccountRepository");

/**
 * Profil użytkownika
 */
export default {
  components: { Layout, PageHeader, Profile, ChangePassword, Preloader },
  page: {
    title: "Profil użytkownika",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      preloader: true,
      loggedUser: null,
      userData: {},
      header: {
        title: "Profil użytkownika",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Profil użytkownika",
            active: true,
          },
        ],
      },
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    this.getAll();
  },
  methods: {
    ...authComputed,
    getAll() {
      UserAccountRepository.getMyAccount()
        .then((response) => {
          this.userData = response.data;
          this.preloader = false;
        })
        .catch((error) => {
          console.log(error);
          this.preloader = false;
        });
    },
  },
};
</script>

<template>

  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />

    <div class="row">
      <div class="col d-flex">
        <Profile :data="userData" />
      </div>
      <template v-if="userData.uuid === loggedUser.uuid && loggedUser.typeOfUserAccount === 1">
        <div class="col-lg-4 d-flex">
          <div class="card shadow flex-fill">
            <div class="card-body">
              <h4 class="card-title">Zmiana hasła</h4>
              <ChangePassword account-uuid="userData.uuid"></ChangePassword>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card shadow">
          <div class="card-body">
            <h4 class="card-title">Dane podstawowe</h4>
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">Imię:</th>
                    <td>{{ userData.firstName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nazwisko:</th>
                    <td>{{ userData.lastName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">E-mail:</th>
                    <td><a :href="'mailto:' + userData.email">{{ userData.email }}</a></td>
                  </tr>
                  <tr>
                    <th scope="row">Stanowisko:</th>
                    <td>{{ userData.position }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Utworzenie konta:</th>
                    <td>{{ userData.createdAt }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Ostatnia aktualizacja:</th>
                    <td>{{ userData.updatedAt }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Typ konta:</th>
                    <td>{{ userData.typeOfUserAccount === 1 ? 'Konto lokalne' : 'Konto Active Directory' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Konto aktywne?</th>
                    <td v-if="userData.isActive">Tak</td>
                    <td v-if="!userData.isActive" style="color: #9e0000">Nie</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="userData.roles">
        <div class="card shadow">
          <div class="card-body">
            <h4 class="card-title">Role w systemie</h4>
            <span class="badge font-size-12 badge-soft-success mr-1" v-for="(role, index) in userData.roles" :key="index">
              {{ role | roleName }}
            </span>
            <template v-if="userData.roles.find(o => o === 'ROLE_APPLICANT')">
              <hr />
              <h4 class="card-title">Uprawnienia wnioskującego</h4>
              <span class="badge font-size-12 badge-soft-info mr-1" v-for="(permission) in userData.permissions" :key="permission.uuid">
                <span v-if="permission.userAccountPermissionType == 1">
                  {{ permission.companyName }}
                </span>
              </span>
            </template>
            <template v-if="userData.roles.find(o => o === 'ROLE_ACCEPTING') || userData.roles.find(o => o === 'ROLE_SUPER_ACCEPTING')">
              <hr />
              <h4 class="card-title">Uprawnienia akceptującego przelewy</h4>
              <span class="badge font-size-12 badge-soft-info mr-1" v-for="(permission) in userData.permissions" :key="permission.uuid">
                <span v-if="permission.userAccountPermissionType == 2">
                  {{ permission.companyName }}
                </span>
              </span>
            </template>
            <template v-if="userData.roles.find(o => o === 'ROLE_ACCEPTING_CARD')">
              <hr />
              <h4 class="card-title">Uprawnienia akceptującego zasilenie kart</h4>
              <span class="badge font-size-12 badge-soft-info mr-1" v-for="(permission) in userData.permissions" :key="permission.uuid">
                <span v-if="permission.userAccountPermissionType == 4">
                  {{ permission.companyName }}
                </span>
              </span>
            </template>
            <template v-if="userData.roles.find(o => o === 'ROLE_ACCEPTING_KRS')">
              <hr />
              <h4 class="card-title">Uprawnienia akceptującego karty nierozliczone</h4>
              <span class="badge font-size-12 badge-soft-info mr-1" v-for="(permission) in userData.permissions" :key="permission.uuid">
                <span v-if="permission.userAccountPermissionType == 3">
                  {{ permission.companyName }}
                </span>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
