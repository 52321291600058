<script>
import AvatarCropper from "vue-avatar-cropper";
import { authComputed, authMethods } from "@/state/helpers";
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";

const UserAccountRepository = Repository.get("UserAccountRepository");

export default {
  components: { AvatarCropper },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 128,
    },
    variant: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      message: "ready",
      loggedUser: null,
      croppedBase64: null,
      config: {
        labels: {
          submit: "Wyślij",
          cancel: "Anuluj",
        },
        options: {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
        },
        resolution: {
          width: 250,
          height: 250,
        },
        quality: 0.8,
        mimes: "image/png, image/gif, image/jpeg",
        outputMime: "image/jpeg",
      },
    };
  },
  created() {
    this.loggedUser = this.currentUser();
  },
  methods: {
    ...authMethods,
    ...authComputed,
    uploadProfileImage(cropped) {
      const croppedImage = cropped
        .getCroppedCanvas(this.config.resolution)
        .toDataURL(this.config.outputMime, this.config.quality);
      const payload = {
        profileImage: croppedImage,
      };

      UserAccountRepository.changeProfileImage(payload)
        .then(() => {
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Zdjęcie profilowe zostało zmienione.",
            showConfirmButton: false,
            timer: 3500,
          });
          this.croppedBase64 = croppedImage;
          this.changeProfileImage({ profileImage: this.croppedBase64 });
        })
        .catch((error) => {
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
  },
  computed: {
    hasAvatar() {
      return (
        this.croppedBase64 === null &&
        this.userData.profileImage !== null &&
        this.userData.profileImage !== ""
      );
    },
    hasNotAvatar() {
      return (
        this.croppedBase64 === null &&
        (this.userData.profileImage === null ||
          this.userData.profileImage === "")
      );
    },
  },
};
</script>
<template>
  <div id="avatar-content" class="vue-avatar-cropper-demo text-center">
    <b-avatar v-if="hasAvatar" :src="userData.profileImage" :size="size" :variant="variant"></b-avatar>
    <b-avatar v-if="hasNotAvatar" :text="userData.firstName.substring(0,1).toLocaleUpperCase()" :size="size" :variant="variant"></b-avatar>
    <b-avatar v-if="croppedBase64 !== null" :src="croppedBase64" :size="size" :variant="variant"></b-avatar>
    <template v-if="userData.userAccountId === loggedUser.userAccountId">
      <button class="btn btn-primary btn-sm" id="pick-avatar">Zmień</button>
      <avatar-cropper :upload-handler="uploadProfileImage" trigger="#pick-avatar" :labels="config.labels" :mimes="config.mimes" />
    </template>
  </div>
</template>